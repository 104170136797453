import '@/plugins/vue-composition-api';
// import '@/styles/styles.scss';
import VueFormulate from '@braid/vue-formulate';
import "@sum.cumo/vue-datepicker/dist/Datepicker.css";
import Vue from 'vue';
import VueFormulateDatepickerPlugin from "vue-formulate-datepicker";
import VueSignaturePad from 'vue-signature-pad';
import WebRTC from 'vue-webrtc';
import { audience, clientId, domain } from '../auth_config.json';
import App from './App.vue';
import './assets/tailwind.css';
import { Auth0Plugin } from './auth';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import VueApollo from "vue-apollo";

import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

Vue.use(VueFormulate, {
  plugins: [VueFormulateDatepickerPlugin],
  classes: {
    // outer: ({ isGrouped }) => (isGrouped ? 'tw-mb-1' : 'tw-mb-4'),

    outer: ({ classification, isGrouped }) => {
      switch (classification) {
        case 'text':
          return isGrouped ? 'tw-mb-1 tw-col-span-6 sm:tw-col-span-3 lg:tw-col-span-2' : 'tw-mb-4 tw-col-span-6 sm:tw-col-span-3 lg:tw-col-span-2'
        case 'box':
          return isGrouped ? 'tw-mb-1 tw-flex tw-items-center' : 'tw-mb-4 tw-col-span-6 sm:tw-col-span-3 tw-flex tw-items-center'
        case 'group':
          return isGrouped ? 'tw-mb-1' : 'tw-mb-4 tw-col-span-6 sm:tw-col-span-3'
        default:
          return isGrouped ? 'tw-mb-1' : 'tw-mb-4'
      }
    },

    wrapper: ({ classification }) => {
      switch (classification) {
        case 'box':
          return 'tw-flex tw-items-center'
        default:
          return ''
      }
    },
    element: ({ classification, hasValue }) => {
      switch (classification) {
        case 'group':
          return 'tw-mt-2'
        case 'select':
          return !hasValue ? 'tw-text-gray-500 tw-font-light' : ''
        default:
          return ''
      }
    },
    input: ({ classification }) => {
      switch (classification) {
        case 'button':
          return 'tw-px-4 tw-py-2 tw-rounded tw-bg-blue-500 tw-text-white hover:tw-bg-blue-600'
        case 'group':
          return ''
        case 'box':
          return 'focus:tw-ring-indigo-500 tw-h-4 tw-w-4 tw-text-indigo-600 tw-border-gray-300'
        default:
          return 'pa-2 tw-ml-3 tw-focus:ring-indigo-500 focus:tw-border-indigo-500 tw-block tw-w-full tw-shadow sm:tw-text-sm tw-border-gray-300 tw-rounded-md'
      }
    },
    decorator: ({ hasValue }) => {
      let base = 'tw-h-4 tw-w-4 tw-text-indigo-600 tw-border-gray-300'
      if (hasValue) {
        base += ' focus:tw-ring-indigo-500'
      }

      return base
    },
    label: 'tw-ml-3 tw-block tw-text-sm tw-font-medium tw-text-gray-700',
    help: 'tw-text-xs tw-mb-1 tw-text-gray-600',
    error: 'tw-text-red-700 tw-text-xs tw-mb-1',
  },
})

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
  },
})

Vue.use(VueSignaturePad);
Vue.config.productionTip = false
Vue.use(WebRTC)
Vue.use(VueFormulate)

const getHeaders = () => {
  const headers = {
    'x-hasura-admin-secret': 'NmK7oyBQgroHnx152sYklelZGcJ9xZB4jQBZA7rcixCZGC34V2apXz9YN4G0VpFq'
  };
   const token = window.localStorage.getItem('apollo-token');
   if (token) {
     headers.authorization = `Bearer ${token}`;
   }
   return headers;
 };
 // Create an http link:
 const link = new HttpLink({
   uri: 'https://stirring-piranha-17.hasura.app/v1/graphql',
   fetch,
   headers: getHeaders()
 });
 const client = new ApolloClient({
   link: link,
   cache: new InMemoryCache({
     addTypename: true
   })
 });
 Vue.use(VueApollo);
 const apolloProvider = new VueApollo({
  defaultClient: client,
})
new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
