/* eslint-disable no-undef */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from '../auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'company',
    beforeEnter: authGuard,
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/lists/tempchart',
    name: 'tempchart',
    component: () => import('@/views/lists/TempChart.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('@/views/company/Company.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/webrtc/:roomId',
    name: 'webrtc',
    component: () => import('@/views/Webrtc.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/peerjs/:roomId',
    name: 'peerjs',
    component: () => import('@/views/Peerjs.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/form',
    name: 'form',
    component: () => import('@/views/PatientForm.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/fluConsent',
    name: 'fluConsent',
    component: () => import('@/views/forms/PatientFormFlu.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/formf',
    name: 'formf',
    component: () => import('@/views/PatientFormFlu.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/Report.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    // path: '/webrtc/:roomId',
    path: '/webrtc/1234',
    name: 'webrtc',
    component: () => import('@/views/main.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
    },
    beforeEnter: authGuard,
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
