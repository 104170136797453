import { mdiFileChartOutline, mdiAccountMultiple, mdiFormatListBulletedTriangle, mdiPrinterSettings, mdiForumOutline, mdiFileChart, mdiCogOutline, mdiCartMinus, mdiSchool, mdiCalendarMonth       } from '@mdi/js';

export default [
  // {
  //   title: 'Worksheet',
  //   icon: mdiFormSelect,
  //   to: 'home',
  // },
  {
    title: 'People',
    icon: mdiAccountMultiple,
    to: 'company',
        badge: 'Active',
    badgeColor: 'success',
  },
  {
    title: 'Calendar',
    icon: mdiCalendarMonth ,
    to: 'apps-calendar',
    badge: 'Active',
    badgeColor: 'success',
  },
  {
    title: 'Lists',
    icon: mdiFormatListBulletedTriangle,
    to: 'tempchart',
    badge: 'Active',
    badgeColor: 'success',
  },
  {
    title: 'Labelling',
    icon: mdiPrinterSettings ,
    // to: 'second-page',
  },
  {
    title: 'Communication',
    icon: mdiForumOutline,
    // to: 'second-page',
  },
  {
    title: 'Reporting',
    icon: mdiFileChart ,
    // to: 'second-page',
  },

  {
    title: 'Settings',
    icon: mdiCogOutline ,
    // to: 'second-page',
  },
  {
    title: 'Supplies',
    icon: mdiCartMinus ,
    // to: 'second-page',
  },
  {
    title: 'Training',
    icon: mdiSchool ,
    // to: 'second-page',
  },
  // {
  //   title: 'Magic',
  //   icon: mdiFileChartOutline,
  //   to: 'peerjs',
  // },
  // {
  //   title: 'Form Magic',
  //   icon: mdiFileChartOutline,
  //   to: 'form',
  // },
  // {
  //   title: 'Webrtc',
  //   icon: mdiFileChartOutline,
  //   to: 'webrtc',
  // },

  // {
  //   title: 'Second Page',
  //   icon: mdiFileOutline,
  //   to: 'second-page',
  // },
  // {
  //   title: 'webrtc',
  //   icon: mdiFileOutline,
  //   to: 'webrtc',
  // },
]
